<template>
	<div class="addwarning">
		<div class="warningmain">
			<div class="wm_content">
				<div class="wmc_select">
					<div class="wmcs_title">
						<span>请选择报警类型</span>
					</div>
					<div class="wmcs_content">
						<div class="wncsc_div" :class="{selectwncsc:warning_type=='none'}" @click="setActive('none')">
							<span>正常</span>
						</div>
						<div class="wncsc_div" :class="{selectwncsc:warning_type=='low'}" @click="setActive('low')">
							<span>提醒</span>
						</div>
						<div class="wncsc_div" :class="{selectwncsc:warning_type=='high'}" @click="setActive('high')">
							<span>报警</span>
						</div>
					</div>
					<div class="wmcs_input">
						<div class="wmcsi_title">
							<span>{{property_name}}最小值</span>
						</div>
						<div class="wncsi_input">
							<input placeholder="请输入最小值" v-model="min_value" type="number"/>
						</div>
					</div>
					<div class="wmcs_input">
						<div class="wmcsi_title">
							<span>{{property_name}}最大值</span>
						</div>
						<div class="wncsi_input">
							<input placeholder="请输入最小值" v-model="max_value" type="number"/>
						</div>
					</div>
				</div>
			</div>

			<div class="wmc_btn">
				<div class="wmcb_add" @click="addWarningType">
					<span>确认新增</span>
				</div>
			</div>
		</div>


	</div>
</template>

<script>


import studio_login_api from '@/api/teaching_studio_no_login.js'
import studio_api from '@/api/teaching_studio_teaching_studio_api.js'
import studio_tool_api from '@/api/teaching_studio_tool.js'
import until from '@/api/until.js'
export default {
	name: 'Home',
	data() {
		return {
			salt:"",
			warning_type:"none",
			property_key:"",
			min_value:0,
			max_value:0,
			property_name:"",
			back_index:1
		}
	},
	components: {

	},
	mounted() {
		document.title="新增报警设置"
		this.property_name=this.$route.query.property_name
		this.back_index=this.$route.query.back_index
		this.getSalt();
	},
	methods: {
		//获取salt
		async getSalt(){
			let computer_code=until.getCookie("guid").substring(0, 6)
			let data=await studio_tool_api.ToolForm_saltGet({computer_code});
			if(data.errcode==0){
				this.salt=data.other;
			}
		},
		setActive(type){
			this.warning_type=type
		},

		//确认新增报警设置
		async addWarningType(){
			let app_id=this.$root.app_id;
			let computer_code=until.getCookie("guid").substring(0, 6)
			let salt=this.salt;
			let property_id=this.$route.query.property_id;
			let min_value=this.min_value;
			let max_value=this.max_value
			let warning_type=this.warning_type

			this.$toast.loading({
				message: "加载中...",
				forbidClick: true,
				duration: 0,
			});

			let data=await studio_api.Teaching_studio_apiIot_device_property_warningAddPost({app_id,computer_code,salt,property_id,min_value,max_value,warning_type});
			if(data.errcode==0){
				this.$toast("新增成功")
				window.history.go(-this.back_index);
			}
			else{
				this.getSalt()
			}
		},


	}
}
</script>
<style scoped>
.addwarning{
	width: 100%;
	background: #F6F6F6;
	height: 100vh;
	display: inline-block;
	overflow: auto;

	box-sizing: border-box;
}
.warningmain{
	width: 100%;
	display: inline-block;
	position: relative;
	height: 100vh;
	padding: 0 30px;
	box-sizing: border-box;
}
.wm_content{
	width: 100%;
	height: calc(100vh - 254px);
	margin-top: 24px;
	background: #ffffff;
	display: inline-block;
}
.wmcs_title{
	width: 100%;
	padding: 0 40px;
	box-sizing: border-box;
	font-size: 28px;
	color: #333333;
	line-height: 40px;
	margin-top: 40px;
	font-weight: bold;

}
.wmcs_content{
	width: 100%;
	margin-top: 24px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 40px;
	box-sizing: border-box;
}
.wncsc_div{
	width: 182px;
	height: 88px;
	background: #F8F8F8;
	border-radius: 8px 8px 8px 8px;
	opacity: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 28px;
	color: #333333;
	line-height: 40px;
	border: 1px solid #F8F8F8;
}
.wmcs_input{
	width: 100%;
	margin-top: 40px;
	padding: 0 40px;
	box-sizing: border-box;

}
.wmcsi_title{
	width: 100%;
	font-size: 28px;
	color: #333333;
	line-height: 40px;
}
.wncsi_input{
	width: 100%;
	height: 88px;
	background: #F8F8F8;
	border-radius: 8px 8px 8px 8px;
	margin-top: 16px;

}
.wncsi_input input{
	width: 100%;
	height: 88px;
	background: #F8F8F8;
	border-radius: 8px 8px 8px 8px;
	border: 0px;
	outline: none;
	padding-left: 24px;
	box-sizing: border-box;
	font-size: 28px;
	color: #333333;
}
.wmc_btn{
	width: 100%;
	margin-top: 72px;
	
}
.wmcb_add{
	width: calc(100% - 80px);
	margin-left: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 28px;
	color: #FFFFFF;
	font-weight: bold;
	height: 88px;
	background: #4E89FF;
	border-radius: 8px 8px 8px 8px;
	opacity: 1;
}
.selectwncsc{
	border: 1px solid #02E4AE;
	background: #FFFFFF;
	color: #02E4AE;
}
</style>

